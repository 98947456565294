.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20000;
}

.datepicker-input {
    width: 50%;
}

.datepicker-popper {
    z-index: 1500 !important;

    .react-datepicker__tab-loop {
        position: relative;
    }
}

.react-datepicker__input-container {
    width: 100%;
}